






























































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { reservationModule } from '@/store';
import dataFormat from '@/shared/utils/dataFormat';
import { CardInstallment, PartialInstallment } from '@/store/reservation/types';

@Component
export default class InstallmentGuideModal extends mixins(dataFormat) {
  get currentMonth(): number {
    return new Date().getMonth() + 1;
  }

  get cardInstallment(): any {
    return reservationModule.getCardIntallment;
  }

  public getFreeInfoString(cardInstallment: CardInstallment): string {
    return this.getMonthString(cardInstallment.quota_month) + '개월 ('
      + this.NumberFormat(cardInstallment.min_amount) + '원 이상)';
  }

  public getPartialInfoString(partialInstallment: PartialInstallment): string {
    return this.getMonthString(partialInstallment.quota_month) + '개월 ('
      + partialInstallment.customer_pay_round + '회차 고객부담)';
  }

  public scroll() {
    const re = document.querySelector('.installment-guide-box') as HTMLDivElement;
    const wrap = document.querySelector('.installment-guide') as HTMLDivElement;
    if (re) {
      if (re.scrollTop > 0) {
        wrap.classList.add('on-scroll');
      } else {
        wrap.classList.remove('on-scroll');
      }
    }
  }

  private getMonthString(months: number[]): string {
    if (months && months.length > 0) {
      if (months.length >= 3) {
        const lastIndex = months.length - 1;
        if (months[0] + lastIndex == months[lastIndex]) {
          return months[0] + '-' + months[months.length-1];
        }
      }
      return months.join(',');
    }
    return '';
  }

  public modalOpened() {
    document.querySelector('html' as any).classList.add('open-modal');
    reservationModule.fetchCardInstallment();
  }

  public modalClosed() {
    document.querySelector('html' as any).classList.remove('open-modal');
  }
}
